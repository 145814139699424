import { AcademicCapIcon } from '@heroicons/react/outline'

import { TEACH_KIRUNDI_BUTTON_TEXT } from '../../constants/strings'

export const PromoNdakundaIkirundi = () => {
  return (
    <div className="mt-7 items-center items-stretch justify-center text-center dark:text-white sm:mt-6">
      <button
        type="button"
        className="w-full rounded-md border border-transparent bg-slate-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 sm:text-sm"
        onClick={(e) => {
          e.preventDefault()
          window.open('https://www.lingu.africa/')
        }}
      >
        <div className="flex items-center justify-center">
          <AcademicCapIcon className="mr-1 h-6 w-6 cursor-pointer dark:stroke-white" />
          {TEACH_KIRUNDI_BUTTON_TEXT}
        </div>
      </button>
    </div>
  )
}
