import { COUNTRIES, CountryKey } from '../../constants/countries'
import {
  COUNTRY_LEADERBOARD_TEXT,
  INDIVIDUAL_LEADERBOARD_TEXT,
  INTERNATIONAL_COMPETITION_TEXT,
  MEDIAN_SCORE_PER_COUNTRY_TEXT,
  MEDIAN_SCORE_WORLDWIDE_TEXT,
  PLAYER_COUNT_LEADERBOARD_TEXT,
  YOUR_POSITION_IN_COUNTRY_TEXT,
  YOUR_POSITION_WORLDWIDE_TEXT,
} from '../../constants/strings'
import { StatItem } from './StatItem'

const { flag } = require('country-emoji')

type Props = {
  rankingStats: RankingStats
}

type BestPlayersProps = {
  rank: number
  score: number
  country: string
}

type PlayersByCountryProps = {
  country: string
  count: number
  rank: number
}

export type RankingStats = {
  national_rank: string
  international_rank: string
  median_national_score: number
  median_international_score: number
  country: string
  best_players: Array<BestPlayersProps>
  players_by_country: Array<PlayersByCountryProps>
}

export const RankingBar = ({
  rankingStats: {
    median_international_score,
    median_national_score,
    country,
    international_rank,
    national_rank,
    best_players,
    players_by_country,
  },
}: Props) => {
  const countryFlag = flag(country === 'DR Congo' ? 'CD' : country)

  const getCountryKirundiName = (country: string) =>
    COUNTRIES[country as CountryKey] || country

  const formatScore = (score: number): string => {
    return score === 0
      ? '-'
      : score.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  }

  return (
    <>
      <p className="text-m mt-2 font-medium leading-6 text-gray-900 dark:text-gray-100">
        {INTERNATIONAL_COMPETITION_TEXT}
      </p>
      <div className="my-2 flex justify-center">
        <StatItem
          label={`${YOUR_POSITION_IN_COUNTRY_TEXT} ${countryFlag}`}
          value={national_rank}
          valueTextSize="text-xs"
        />
        <StatItem
          label={YOUR_POSITION_WORLDWIDE_TEXT}
          value={`${international_rank}`}
          valueTextSize="text-xs"
        />
        <StatItem
          label={`${MEDIAN_SCORE_PER_COUNTRY_TEXT} ${countryFlag}`}
          value={formatScore(median_national_score)}
          valueTextSize="text-xs"
        />
        <StatItem
          label={MEDIAN_SCORE_WORLDWIDE_TEXT}
          value={formatScore(median_international_score)}
          valueTextSize="text-xs"
        />
      </div>
      {best_players.length > 0 && (
        <>
          <p className="text-m mt-3 mb-2 font-medium leading-6 text-gray-900 dark:text-gray-100">
            {INDIVIDUAL_LEADERBOARD_TEXT}
          </p>
          <div className="text-s flex justify-center text-gray-900 dark:text-gray-100">
            <table className="border-collapse border border-slate-500">
              <thead>
                <tr className="text-sm">
                  <th className="border border-slate-600 px-5">#</th>
                  <th className="border border-slate-600 px-5">igihugu</th>
                  <th className="border border-slate-600 px-7">amanota</th>
                </tr>
                {best_players.map(({ rank, score, country }, i) => {
                  return (
                    <tr
                      className="text-xs"
                      key={`${rank}-${score}-${country}-${i}`}
                    >
                      <td className="border border-slate-700 p-1">{rank}</td>
                      <td className="border border-slate-700 p-1">
                        {flag(country === 'DR Congo' ? 'CD' : country)}{' '}
                        {getCountryKirundiName(country)}
                      </td>
                      <td className="border border-slate-700 p-1">
                        {score.toLocaleString('fr')}
                      </td>
                    </tr>
                  )
                })}
              </thead>
            </table>
          </div>
        </>
      )}
      {false && (
        <>
          <p className="text-m mt-3 mb-2 font-medium leading-6 text-gray-900 dark:text-gray-100">
            {COUNTRY_LEADERBOARD_TEXT}
          </p>
          <div className="text-s flex justify-center text-gray-900 dark:text-gray-100">
            <table className="border-collapse border border-slate-500">
              <thead>
                <tr className="text-sm">
                  <th className="border border-slate-600 px-5">#</th>
                  <th className="border border-slate-600 px-5">igihugu</th>
                  <th className="border border-slate-600 px-7">amanota</th>
                </tr>
                {players_by_country.map(({ rank, count, country }, i) => {
                  return (
                    <tr className="text-xs" key={`country-${i}`}>
                      <td className="border border-slate-700 p-1">
                        🥇 {i + 1} 🥈 🥉
                      </td>
                      <td className="border border-slate-700 p-1">
                        Ubufaransa 🇫🇷
                      </td>
                      <td className="border border-slate-700 p-1">323994</td>
                    </tr>
                  )
                })}
              </thead>
            </table>
          </div>
        </>
      )}
      {players_by_country.length > 0 && (
        <>
          <p className="text-m mt-3 mb-2 font-medium leading-6 text-gray-900 dark:text-gray-100">
            {PLAYER_COUNT_LEADERBOARD_TEXT}
          </p>
          <div className="text-s flex justify-center text-gray-900 dark:text-gray-100">
            <table className="border-collapse border border-slate-500">
              <thead>
                <tr className="text-sm">
                  <th className="border border-slate-600 px-5">#</th>
                  <th className="border border-slate-600 px-5">igihugu</th>
                  <th className="border border-slate-600 px-7">abakinyi</th>
                </tr>
                {players_by_country.map(({ rank, count, country }, i) => {
                  return (
                    <tr className="text-xs" key={`${rank}-${country}-${count}`}>
                      <td className="border border-slate-700 p-1">{rank}</td>
                      <td className="border border-slate-700 p-1">
                        {flag(country === 'DR Congo' ? 'CD' : country)}{' '}
                        {getCountryKirundiName(country)}
                      </td>
                      <td className="border border-slate-700 p-1">
                        {count.toLocaleString('fr')}
                      </td>
                    </tr>
                  )
                })}
              </thead>
            </table>
          </div>
        </>
      )}
    </>
  )
}
