export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!

export const WIN_MESSAGES = [
  '✅ Ikibazo uragitoye 👍🏾!',
  "🔥 Ubitoye nk'uwadubuye 👍🏾 !",
  '💯 Neza cane 👍🏾 !',
  '🤩 Uri incambwenge kweri 👍🏾 !',
  '🎉 Urarigemye 👍🏾 !',
  "🥇 Ukwiye umudari w'inzahabu 👍🏾!",
]
export const GAME_COPIED_MESSAGE = 'Urukino rwakoporowe'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Indome ntizikwiye'
export const WORD_NOT_FOUND_MESSAGE =
  '🙅🏾‍♂️ 🙅🏾‍♀️ Iryo jambo ntiririmwo. Urakuwe amanota 10. Gerageza irindi.'
export const HARD_MODE_ALERT_MESSAGE =
  'Urukino rugoye ushobora kurujamwo ariho ugitangura gukina gusa!'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Ijambo ryinyegeje ryari ${solution}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Utegerezwa gukoresha ${guess} mu kibanza ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Ijambo ritegerezwa kuba ririmwo urudome ${letter}`
export const ENTER_TEXT = 'EMEZA'
export const STATISTICS_TITLE = 'Aho ugejeje urukino'
export const GUESS_DISTRIBUTION_TEXT = 'Uko wagiye urabitora'
export const NEW_WORD_TEXT = 'Ijambo rishasha riza gushika muri : '
export const SHARE_TEXT = 'Sangiza abandi'
export const SEND_TEXT = 'Rungika'
export const TOTAL_TRIES_TEXT = 'Incuro umaze gukina'
export const SUCCESS_RATE_TEXT = 'Incuro wabitoye'
export const CURRENT_STREAK_TEXT = 'Iminsi umaze utsinda wikurikiranya'
export const BEST_STREAK_TEXT = 'Iminsi myinshi watsinze wikurikiranya'
export const HARD_MODE_DESCRIPTION =
  'Indome zose weretswe zitegerezwa gukoreshwa mu majambo akurikira'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'Kugira bisomeke neza uri ku zuba'
export const INTERNATIONAL_COMPETITION_TEXT = 'Ihiganwa mpuzamakungu'
export const INDIVIDUAL_LEADERBOARD_TEXT = '🏆 Abakinyi bafise amanota menshi'
export const COUNTRY_LEADERBOARD_TEXT = '🏆 Ibihugu bifise amanota menshi'
export const PLAYER_COUNT_LEADERBOARD_TEXT = '🏆 Ibihugu bifise abakinyi benshi'
export const YOUR_SCORE_TEXT = 'Ufise amanota'
export const YOUR_POSITION_IN_COUNTRY_TEXT = 'Ikibanza cawe mu gihugu'
export const YOUR_POSITION_WORLDWIDE_TEXT = "Ikibanza cawe kw'isi yose"
export const MEDIAN_SCORE_PER_COUNTRY_TEXT = "Incahagati y'amanota ku gihugu"
export const MEDIAN_SCORE_WORLDWIDE_TEXT = "Incahagati y'amanota kw'isi yose"
export const MEANINGS_TITLE = 'Twungurane ubumenyi'
export const TIME_TAKEN_TEXT = 'Uru rukino rwamaze'
export const DELETE_TEXT = 'Delete'
export const SHARE_FAILURE_TEXT =
  'Unable to share the results. This feature is available only in secure contexts (HTTPS), in some or all supporting browsers.'
export const MIGRATE_BUTTON_TEXT = 'Transfer'
export const MIGRATE_DESCRIPTION_TEXT =
  'Click here to transfer your statistics to a new device.'
export const DISCOURAGE_INAPP_BROWSER_TEXT =
  "You are using an embedded browser and may experience problems sharing or saving your results. We encourage you rather to use your device's default browser."

export const DATEPICKER_TITLE = "Urukino rw'uyundi munsi"
export const DATEPICKER_CHOOSE_TEXT = 'Tora'
export const DATEPICKER_TODAY_TEXT = 'uno munsi'
export const ARCHIVE_GAMEDATE_TEXT = 'Urukino rwo ku wa'
export const TEACH_KIRUNDI_BUTTON_TEXT = 'Twigishe ikirundi abana bacu'
export const SETTINGS_MODAL_TITLE = 'Kuregera'
export const CURRENT_DATE_TEXT = "Itariki ry'uno munsi"
