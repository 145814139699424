import {
  Timestamp,
  addDoc,
  collection,
  getDocs,
  query,
  where,
} from 'firebase/firestore'

import { RankingStats } from '../components/stats/RankingBar'
import { db } from './firebase'

export type CompletedGamePayload = {
  country: string
  end_time: Timestamp
  guesses: string[]
  score: number
  solution: string
  start_time: Timestamp
  time_taken: number
  timezone: string
  won: boolean
  latest: boolean
}

export const saveGameToFirebase = async (
  won: boolean,
  isLatestGame: boolean,
  game: any,
  score: number,
  startTime: Date,
  endTime: Date,
  timeTaken: number,
  country: string,
  timezone: string
) => {
  try {
    const completedGame: CompletedGamePayload = {
      end_time: Timestamp.fromDate(endTime),
      guesses: game.guesses || [],
      score,
      solution: game?.solution,
      start_time: Timestamp.fromDate(startTime),
      time_taken: timeTaken,
      won,
      latest: isLatestGame,
      country,
      timezone,
    }

    await addDoc(collection(db, 'games'), completedGame)
    return true
  } catch (error) {
    console.error('Error saving game:', error)
    return false
  }
}

export const saveMissingWordToFirebase = async (word: string) => {
  try {
    await addDoc(collection(db, 'missing_words'), {
      value: word.toLowerCase(),
      created_at: new Date(),
    })
    return true
  } catch (error) {
    console.error('Error saving missing word:', error)
    return false
  }
}

export const saveMeaningToFirebase = async (
  keyword: string,
  proverb: string,
  meaning: string
) => {
  try {
    await addDoc(collection(db, 'meanings'), {
      keyword,
      proverb,
      meaning,
      created_at: new Date(),
    })
    return true
  } catch (error) {
    console.error('Error saving meaning:', error)
    return false
  }
}

export const getRankingStats = async (
  score: number,
  country: string,
  solution: string
): Promise<RankingStats> => {
  try {
    // Calculate time range for latest games (24 hours before and after current time)
    const now = Timestamp.now()
    const oneDayInSeconds = 24 * 60 * 60
    const oneDayAgo = new Timestamp(
      now.seconds - oneDayInSeconds,
      now.nanoseconds
    )
    const oneDayAhead = new Timestamp(
      now.seconds + oneDayInSeconds,
      now.nanoseconds
    )

    console.log('Time range:', {
      now: now.toDate(),
      oneDayAgo: oneDayAgo.toDate(),
      oneDayAhead: oneDayAhead.toDate(),
    })

    // Get all games for the current solution within the time window
    const gamesRef = collection(db, 'games')
    const gamesWithSolutionQuery = query(
      gamesRef,
      where('solution', '==', solution)
    )
    const querySnapshot = await getDocs(gamesWithSolutionQuery)

    console.log('Found games:', querySnapshot.size)

    const gamesWithSolution = querySnapshot.docs.map((doc) => {
      const data = doc.data()
      console.log('Game data:', {
        solution: data.solution,
        start_time: data.start_time?.toDate(),
        score: data.score,
        country: data.country,
      })
      return data
    })

    // Filter games within the time window in memory since Firestore doesn't support multiple range queries
    const filteredGames = gamesWithSolution.filter((game) => {
      const gameStartTime = game.start_time?.toDate?.()
      return (
        gameStartTime &&
        gameStartTime >= oneDayAgo.toDate() &&
        gameStartTime <= oneDayAhead.toDate()
      )
    })

    console.log('Filtered games:', filteredGames.length)

    const gamesWonWithSolution = filteredGames.filter((game) => game.won)

    // Calculate international rank
    const internationalRank =
      filteredGames.length === 0
        ? '-'
        : `${
            gamesWonWithSolution.filter((game) => game.score > score).length + 1
          }/${filteredGames.length}`

    // Calculate national rank
    const nationalGames = filteredGames.filter(
      (game) => game.country === country
    )
    const nationalGamesWon = gamesWonWithSolution.filter(
      (game) => game.country === country
    )
    const nationalRank =
      nationalGames.length === 0
        ? '-'
        : `${
            nationalGamesWon.filter((game) => game.score > score).length + 1
          }/${nationalGames.length}`

    // Calculate median scores
    const medianInternationalScore =
      gamesWonWithSolution.length === 0
        ? 0
        : calculateMedian(gamesWonWithSolution.map((game) => game.score))

    const medianNationalScore =
      nationalGamesWon.length === 0
        ? 0
        : calculateMedian(nationalGamesWon.map((game) => game.score))

    // Get best players (top 10)
    const bestPlayers = gamesWonWithSolution
      .sort((a, b) => b.score - a.score)
      .slice(0, 10)
      .map((game, index) => ({
        rank: index + 1,
        country: game.country,
        score: game.score,
      }))

    // Get players by country (top 10)
    const playersByCountry = Object.entries(
      filteredGames.reduce((acc: { [key: string]: number }, game) => {
        acc[game.country] = (acc[game.country] || 0) + 1
        return acc
      }, {})
    )
      .sort(([countryA, countA], [countryB, countB]) => {
        // Sort by count descending, then by country name ascending
        if (countB !== countA) {
          return countB - countA
        }
        return countryA.localeCompare(countryB)
      })
      .slice(0, 10)
      .map(([country, count], index) => ({
        rank: index + 1,
        country,
        count,
      }))

    return {
      national_rank: nationalRank,
      international_rank: internationalRank,
      median_national_score: medianNationalScore,
      median_international_score: medianInternationalScore,
      country,
      best_players: bestPlayers,
      players_by_country: playersByCountry,
    }
  } catch (error) {
    console.error('Error getting ranking stats:', error)
    return {
      national_rank: '-',
      international_rank: '-',
      median_national_score: 0,
      median_international_score: 0,
      country,
      best_players: [],
      players_by_country: [],
    }
  }
}

const calculateMedian = (numbers: number[]): number => {
  if (numbers.length === 0) return 0

  const sorted = numbers.sort((a, b) => a - b)
  const middle = Math.floor(sorted.length / 2)

  if (sorted.length % 2 === 0) {
    return Math.round((sorted[middle - 1] + sorted[middle]) / 2)
  }

  return Math.round(sorted[middle])
}
