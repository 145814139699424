import { VALID_GUESSES } from '../../constants/validGuesses'
import { WORDS } from '../../constants/wordlist'
import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'
import { Link } from './Link'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal
      title="Urukino rugenda uku :"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <div>
        <p className="text-sm text-gray-500 dark:text-gray-300">
          Tora ijambo ryinyegeje. Ushobora kugerageza incuro 6. Uko ukina,
          amabara y'indome araza guhinduka. Ayo mabara aza kukwereka ko uriko
          uregereza gutora ijambo ryinyegeje.
        </p>
      </div>

      <div>
        <p className="mt-4 text-sm font-bold text-gray-500 dark:text-gray-300">
          Akarorero :
        </p>
      </div>

      <div className="mb-1 mt-2 flex justify-center">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="U"
          status="correct"
        />
        <Cell value="M" />
        <Cell value="U" />
        <Cell value="T" />
        <Cell value="I" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Urudome <span className="font-bold">U</span> ruri mw'ijambo ryinyegeje
        kandi ruri mu kibanza carwo.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="A" />
        <Cell value="M" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="A"
          status="present"
        />
        <Cell value="T" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Urudome <span className="font-bold">A</span> ruri mw'ijambo ryinyegeje
        ariko ntiruri mu kibanza carwo.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="I" />
        <Cell value="M" />
        <Cell value="E" />
        <Cell isRevealing={true} isCompleted={true} value="Z" status="absent" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Urudome <span className="font-bold">Z</span> ntirurimwo mw'ijambo
        ryinyegeje.
      </p>

      <p className="mt-6 text-xs italic text-gray-500 dark:text-gray-300">
        Hari ikibazo wagize mu gukina ? Bimenyeshe mu gasandugu k'amakete kuri
        'Twitter' -{' '}
        <Link
          href="https://twitter.com/lionelkubwimana"
          className="font-bold underline"
          text="Fyonda hano"
        />
      </p>

      <p className="mt-6 text-xs italic text-gray-500 dark:text-gray-300">
        Muri runo rukino, harimwo amajambo{' '}
        <span className="font-bold">
          {new Set(WORDS.concat(VALID_GUESSES)).size}
        </span>{' '}
        gusa.
      </p>

      <p className="mt-4 text-xs italic text-gray-500 dark:text-gray-300">
        <span className="font-bold">Ijambo</span> ryinyegeje ni rimwe umunsi
        wose. Rihinduka isaha 12 zo mw'ijoro.
      </p>
    </BaseModal>
  )
}
