import {
  HARD_MODE_DESCRIPTION,
  HIGH_CONTRAST_MODE_DESCRIPTION,
  SETTINGS_MODAL_TITLE,
} from '../../constants/strings'
import { solutionIndex } from '../../lib/words'
import { BaseModal } from './BaseModal'
import { Link } from './Link'
import { SettingsToggle } from './SettingsToggle'

type Props = {
  isOpen: boolean
  handleClose: () => void
  isHardMode: boolean
  handleHardMode: Function
  isDarkMode: boolean
  handleDarkMode: Function
  isHighContrastMode: boolean
  handleHighContrastMode: Function
}

export const SettingsModal = ({
  isOpen,
  handleClose,
  isHardMode,
  handleHardMode,
  isDarkMode,
  handleDarkMode,
  isHighContrastMode,
  handleHighContrastMode,
}: Props) => {
  return (
    <BaseModal
      title={SETTINGS_MODAL_TITLE}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <div className="mt-2 flex flex-col divide-y">
        <SettingsToggle
          settingName="Urukino rugoye"
          flag={isHardMode}
          handleFlag={handleHardMode}
          description={HARD_MODE_DESCRIPTION}
        />
        <SettingsToggle
          settingName="Amabara yoroshe"
          flag={isDarkMode}
          handleFlag={handleDarkMode}
        />
        <SettingsToggle
          settingName="Amabara amena amaso"
          flag={isHighContrastMode}
          handleFlag={handleHighContrastMode}
          description={HIGH_CONTRAST_MODE_DESCRIPTION}
        />
        <div className="flex justify-between gap-4 py-3">
          <p className="mt-2 text-xs text-gray-500 dark:text-gray-300">
            <Link
              href="https://www.lingu.africa"
              text={`${new Date().getFullYear()} Lingu.Africa`}
            />
          </p>
          <p className="mt-2 text-xs text-gray-500 dark:text-gray-300">
            #{solutionIndex}
          </p>
        </div>
      </div>
    </BaseModal>
  )
}
