import axios from 'axios'

import { COUNTRY_ENDPOINT } from '../constants/endpoints'
import { secondsToHms } from '../lib/duration'
import {
  saveGameToFirebase,
  saveMeaningToFirebase,
  saveMissingWordToFirebase,
} from './firebaseDb'
import { getStoredPenalty, loadGameStateFromLocalStorage } from './localStorage'
import { loadStats } from './stats'

const computeScore = () => {
  const penalty = getStoredPenalty()
  const { winDistribution, bestStreak } = loadStats()
  const weighting = [60, 50, 40, 30, 20, 10]
  const score = weighting.reduce(
    (r, a, index) => r + a * winDistribution[index],
    0
  )
  return (score - 10 * penalty) * bestStreak
}

export const saveGameStateToDatabase = async (
  won: boolean,
  isLatestGame: boolean
) => {
  if (localStorage.getItem('saved')) return

  const game = loadGameStateFromLocalStorage(isLatestGame)
  const startTime = new Date(localStorage.getItem('startTime') as string)
  const endTime = new Date()
  const timeTaken = Math.floor((endTime.getTime() - startTime.getTime()) / 1000)

  const score = computeScore()
  localStorage.removeItem('penalty')
  localStorage.setItem('gameScore', score.toString())
  localStorage.setItem('timeTaken', secondsToHms(timeTaken))

  try {
    const {
      data: { country_name, timezone },
    } = await axios.get(COUNTRY_ENDPOINT)
    localStorage.setItem('country', country_name)

    const saved = await saveGameToFirebase(
      won,
      isLatestGame,
      game,
      score,
      startTime,
      endTime,
      timeTaken,
      country_name,
      timezone
    )

    if (saved) {
      localStorage.setItem('saved', 'true')
    }
  } catch (error) {
    console.error('Error saving game state:', error)
  }
}

export const saveCurrentGuessToDatabase = async (currentGuess: string) => {
  const penalty = getStoredPenalty() + 1
  localStorage.setItem('penalty', penalty.toString())
  await saveMissingWordToFirebase(currentGuess)
}

export const saveMeaningToDatabase = async (
  keyword: string,
  proverb: string,
  meaning: string
) => {
  await saveMeaningToFirebase(keyword, proverb, meaning)
}
